export const Icons = {
    arrow: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            \t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <rect y="23.5" style="fill:#FFFFFF;" width="48.2" height="2.4"/>
            <polygon style="fill:#FFFFFF;" points="24.9,47.3 23.3,45.5 46.4,24.7 23.3,3.9 24.9,2.2 50,24.7 "/>
            </svg>`,
    arrowtheme: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            \t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <rect y="23.5" style="fill:#004361;" width="48.2" height="2.4"/>
            <polygon style="fill:#004361;" points="24.9,47.3 23.3,45.5 46.4,24.7 23.3,3.9 24.9,2.2 50,24.7 "/>
            </svg>`,
    correct : `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            \t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <path style="fill:#199B2C;" d="M47.7,6.8c-0.4,0.1-0.8,0.3-1.2,0.6C36.3,17.7,26.9,27.8,16.8,38L3.3,27.2l0,0
            \tc-0.9-0.7-2.1-0.5-2.8,0.3s-0.5,2.1,0.3,2.8c0,0,0,0,0,0l15,12c0.8,0.6,1.9,0.6,2.7-0.2c10.7-10.7,20.3-21.3,31-32
            \tc0.8-0.7,0.9-2,0.1-2.8C49,6.9,48.4,6.7,47.7,6.8z"/>
            </svg>`,
    leaf: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            \t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <path style="fill:#004361;" d="M37.6,3c0.3-0.8,0.8-1.6,1-2.4c0-0.2-0.1-0.4-0.3-0.5c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.3-0.1-0.5,0
            \tc-3.9,2.3-7.9,4.4-12.1,6.2c-3.9,1.6-7.5,4-10.6,7c-2.3,2.4-3.7,5.5-4,8.7c-0.2,3,0.3,6,1.6,8.7c1.2,2.8,3.5,5,6.4,6
            \tc-0.1,4.2,0,8.5,0.4,12.7c0,0.5,0.8,0.5,0.8,0c-0.3-4.2-0.3-8.4-0.1-12.6c5.7,0.7,11.4-1.7,14.9-6.3c1.8-2.4,2.8-5.2,2.8-8.2
            \tc0-1.8-0.2-3.5-0.5-5.3c-0.3-1.9-0.4-3.8-0.4-5.7c0-1.9,0-3.9,0.2-5.8C37.2,4.7,37.4,3.9,37.6,3L37.6,3z M35.5,27.4
            \tc-1.2,2.7-3.3,5-5.9,6.5c-2.9,1.5-6.1,2.3-9.3,2.1c0.2-2.5,0.7-4.9,1.4-7.3c2-6.4,6.4-11.2,10.7-16.1c0.2-0.2,0.2-0.5,0-0.7
            \tc-0.2-0.2-0.5-0.2-0.7,0l0,0c-5.1,4.3-8.9,9.9-11.1,16.2c-0.8,2.4-1.2,5-1.4,7.5c-0.9-0.5-1.8-1.1-2.6-1.8c-1-1-1.8-2.1-2.4-3.3
            \tc-1.3-2.5-1.8-5.4-1.7-8.2c0.5-7.8,7.7-11.8,14-14.9c2.2-1.1,4.5-2.2,6.7-3.3c1.2-0.5,2.4-1.1,3.5-1.8c-0.1,0.3-0.2,0.7-0.3,1
            \tC36,4.6,35.8,6,35.7,7.4c-0.2,3.4-0.1,6.9,0.4,10.3C36.9,20.9,36.7,24.3,35.5,27.4z"/>
            <path style="fill:#004361;" d="M19.9,50c-0.2,0-0.5-0.2-0.5-0.5c-0.4-4.2-0.6-8.4-0.4-12.7c-2.9-1-5.2-3.2-6.4-6.1
            \tc-1.3-2.7-1.9-5.7-1.6-8.8c0.3-3.3,1.7-6.4,4-8.8c3.1-3,6.7-5.3,10.7-7c4.1-1.8,8.2-3.9,12.1-6.2C37.8,0,38,0,38.2,0.1
            \tc0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4c-0.1,0.4-0.3,0.8-0.5,1.2C38,2.3,37.9,2.6,37.7,3l0,0l0,0
            \tc-0.2,0.8-0.4,1.7-0.4,2.5c-0.2,2-0.2,4.1-0.2,5.8c0,1.9,0.2,3.8,0.4,5.7c0.3,1.7,0.5,3.5,0.5,5.3c-0.1,3-1.1,5.9-2.9,8.3
            \tc-3.5,4.6-9.2,7-14.9,6.4c-0.2,4.1-0.2,8.3,0.1,12.5C20.4,49.8,20.1,50,19.9,50C19.9,50,19.9,50,19.9,50z M37.9,0.2
            \tc-0.1,0-0.1,0-0.2,0c-3.9,2.3-8,4.4-12.1,6.2c-3.9,1.7-7.5,4-10.6,6.9c-2.2,2.4-3.6,5.4-3.9,8.7c-0.2,3,0.3,6,1.6,8.7
            \tc1.2,2.8,3.5,5,6.3,6l0.1,0l0,0.1c-0.1,4.2,0,8.5,0.4,12.7c0,0.2,0.2,0.3,0.3,0.3c0.1,0,0.2-0.1,0.2-0.3c-0.3-4.2-0.3-8.4-0.1-12.6
            \tl0-0.1l0.1,0c5.7,0.7,11.4-1.8,14.8-6.3c1.8-2.4,2.8-5.2,2.8-8.2c0-1.8-0.2-3.5-0.5-5.2c-0.3-1.9-0.4-3.8-0.4-5.8
            \tc0-1.8,0-3.8,0.2-5.8c0.1-0.8,0.2-1.7,0.4-2.5l0,0c0.1-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.4-0.8,0.5-1.2c0-0.1,0-0.1,0-0.2
            \tc0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.1,0l0,0l0,0C38.1,0.2,38,0.2,37.9,0.2z M20.9,36.1c-0.2,0-0.4,0-0.7,0l-0.1,0l0-0.1
            \tc0.2-2.5,0.7-4.9,1.4-7.3c2-6.2,6.2-11,10.3-15.7l0.4-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.2-0.2-0.4-0.2-0.6,0
            \tc-5,4.3-8.9,9.9-11.1,16.1c-0.8,2.4-1.2,5-1.4,7.5l0,0.2l-0.1-0.1c-0.9-0.5-1.8-1.1-2.6-1.8c-1-1-1.8-2.1-2.5-3.3
            \tc-1.3-2.5-1.9-5.4-1.7-8.2c0.5-7.7,7.2-11.6,14-15L33.1,4c1.2-0.5,2.4-1.1,3.5-1.8l0.2-0.1l-0.1,0.3c-0.1,0.3-0.2,0.7-0.3,1
            \tc-0.3,1.3-0.5,2.7-0.6,4.1c-0.2,3.4-0.1,6.9,0.4,10.3c0.8,3.2,0.6,6.6-0.6,9.7l0,0c-1.2,2.8-3.3,5-5.9,6.5
            \tC27,35.4,24,36.1,20.9,36.1z M20.4,35.9c0.2,0,0.4,0,0.6,0c3,0,6-0.7,8.6-2.1c2.6-1.5,4.6-3.7,5.9-6.5c1.1-3.1,1.3-6.4,0.6-9.6
            \tc-0.5-3.4-0.6-6.9-0.4-10.3c0-1.4,0.2-2.8,0.6-4.1c0.1-0.2,0.1-0.5,0.2-0.7c-1.1,0.6-2.2,1.2-3.3,1.7l-6.7,3.3
            \tc-6.8,3.4-13.4,7.3-13.9,14.8c-0.2,2.8,0.4,5.6,1.7,8.1c0.6,1.2,1.4,2.3,2.4,3.3c0.7,0.6,1.6,1.2,2.4,1.7c0.2-2.5,0.7-5,1.4-7.4
            \tc2.2-6.3,6.1-11.9,11.1-16.2c0.2-0.2,0.6-0.2,0.9,0c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4l-0.4,0.4
            \tc-4.1,4.7-8.3,9.5-10.3,15.7C21.1,31.1,20.6,33.5,20.4,35.9z"/>
            </svg>`,
    lightning: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            \t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#004361;" d="M10,25.4h13.5c0.4,0,0.6,0.3,0.6,0.6c0,0.1,0,0.2,0,0.2
            \tl-7.3,19.9l22.3-24.7H25.9c-0.4,0-0.6-0.3-0.6-0.6c0-0.1,0-0.2,0.1-0.3L36.3,1.6H21L10,25.4z M15.1,49.6c-0.4,0-0.6-0.3-0.6-0.6
            \tc0-0.1,0-0.1,0-0.2l8.2-22.1H9c-0.4,0-0.6-0.3-0.6-0.6c0-0.1,0-0.2,0.1-0.3L20,0.7c0.1-0.2,0.3-0.4,0.6-0.4h16.8
            \tC37.8,0.4,38,0.6,38,1c0,0.1,0,0.2-0.1,0.3L27,20.1h13.6c0.4,0,0.6,0.3,0.6,0.7c0,0.2-0.1,0.3-0.2,0.4L15.5,49.4
            \tC15.4,49.6,15.2,49.6,15.1,49.6L15.1,49.6z"/>
            <path style="fill:#004361;" d="M15.1,50L15.1,50c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.1,0-0.2,0.1-0.3l8-21.6H9
            \tc-0.5,0-1-0.4-1-1c0-0.1,0-0.3,0.1-0.4l11.6-25C19.9,0.2,20.2,0,20.6,0c0,0,0,0,0,0h16.8h0c0.3,0,0.5,0.1,0.7,0.3
            \tc0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.4-0.1,0.5L27.6,19.7h13c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2-0.1,0.5-0.3,0.7
            \tL15.8,49.7C15.6,49.9,15.3,50,15.1,50z M20.6,0.7c-0.1,0-0.2,0.1-0.3,0.2l-11.6,25c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.3,0.3h14.1
            \tl-8.3,22.6c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h0c0.1,0,0.2,0,0.2-0.1l25.5-28.3c0-0.1,0.1-0.1,0.1-0.2
            \tc0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1H26.4L37.7,1.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1
            \tc0,0,0,0,0,0H20.6L20.6,0.7z M17.1,46.3L16.5,46l7.3-19.9c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.3-0.3h-14L20.8,1.3h16.1L25.7,20.6
            \tc0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.3,0.3h14L17.1,46.3z M10.6,25h12.9c0.5,0,1,0.4,1,1c0,0.1,0,0.2-0.1,0.3l-6.7,18.1l20.6-22.8H25.9
            \tc-0.5,0-1-0.4-1-1c0-0.2,0-0.3,0.1-0.5L35.7,2H21.3L10.6,25z"/>
            </svg>`,
    upwards: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            \t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <path style="fill:#004361;" d="M38,11.5v2.6h7.6l-18,17.7L15.8,20.4L0,36.1l1.8,1.8l14-13.9l11.7,11.4l19.9-19.5v7.8H50V11.5H38z"/>
            </svg>`,
    close: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
<rect x="23.6" y="-8.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.3553 25.0005)" style="fill:#004361;" width="2.8" height="67.9"/>
<rect x="-8.9" y="23.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.3553 25)" style="fill:#004361;" width="67.9" height="2.8"/>
</svg>`,
    mobile: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
<path id="Path_9" style="fill:#004361;" d="M34.9,0H14.6c-2.1,0-3.7,1.7-3.7,3.7c0,0,0,0,0,0v42.6c0,2.1,1.7,3.7,3.7,3.7H35
\tc2.1,0,3.7-1.7,3.7-3.7V3.7C38.7,1.7,37,0,34.9,0z M21.1,3.2h7.4c0.2,0,0.5,0.1,0.5,0.4s-0.1,0.5-0.4,0.5c0,0-0.1,0-0.1,0h-7.4
\tc-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0C20.6,3.4,20.8,3.2,21.1,3.2 M24.8,48.1c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8
\tc1,0,1.8,0.8,1.8,1.8l0,0C26.6,47.3,25.8,48.1,24.8,48.1 M36.8,42.6H12.7V7.4h24L36.8,42.6L36.8,42.6z"/>
</svg>`
};