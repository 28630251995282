import { createI18n } from "vue-i18n";

const messages = {
    nl: {

    }
};

const i18n = createI18n({
    legacy: true,
    locale: "nl",
    globalInjection: true,
    silentTranslationWarn: true,
    formatFallbackMessages: false, // DONT ACTIVATE || THIS RESULTS IN EVAL || IT WOULD BE EASIER BUT CAN'T BE USED
    messages
});

export default i18n;