// Helper functions
import { mergeDeep } from "helpers/_objects";

// Configs
import * as brand from './module/brand';
import * as moduleConfig from './module/config';
import * as historylog from './module/historylog';
import * as links from './module/links';
import * as message from './module/message';
import * as navigation from './module/navigation';
import * as notification from './module/notification';
import * as product from './module/product';
import * as productexportchannel from './module/productexportchannel';
import * as productgroup from './module/productgroup';
import * as refine from './module/refine';
import * as refinegroup from './module/refinegroup';
import * as upload from './module/upload';
import * as user from './module/user';
import * as workflowboard from './module/workflowboard';
import * as workflowboardbutton from './module/workflowboardbutton';
import * as workflowcard from './module/workflowcard';
import * as workflowcardtype from './module/workflowcardtype';
import * as workflowemailnotification from './module/workflowemailnotification';
import * as workflowevent from './module/workflowevent';
import * as workfloweventaction from './module/workfloweventaction';
import * as workflowfilter from './module/workflowfilter';
import * as workflowlayoutcardoverview from './module/workflowlayoutcardoverview';
import * as workflowlayoutdetail from './module/workflowlayoutdetail';
import * as workflowlayoutdetailtab from './module/workflowlayoutdetailtab';
import * as workflowlayoutoverview from './module/workflowlayoutoverview';
import * as workflowlayouttitle from './module/workflowlayouttitle';
import * as workflownotification from './module/workflownotification';
import * as workflowphase from './module/workflowphase';
import * as workflowproperty from './module/workflowproperty';
import * as workflowpropertyfield from './module/workflowpropertyfield';
import * as workflowpropertyfieldgroup from './module/workflowpropertyfieldgroup';
import * as workflowusergroup from './module/workflowusergroup';
import sentry from "./sentry";

export const apiHostname = "https://src.wingzz-base-v90._playground.localhost.ibizz.nl/";

const config = {
    sentry,
    brand,
    config: moduleConfig,
    historylog,
    links,
    message,
    navigation,
    notification,
    product,
    productexportchannel,
    productgroup,
    refine,
    refinegroup,
    upload,
    user,
    workflowboard,
    workflowboardbutton,
    workflowcard,
    workflowcardtype,
    workflowemailnotification,
    workflowevent,
    workfloweventaction,
    workflowfilter,
    workflowlayoutcardoverview,
    workflowlayoutdetail,
    workflowlayoutdetailtab,
    workflowlayoutoverview,
    workflowlayouttitle,
    workflownotification,
    workflowphase,
    workflowproperty,
    workflowpropertyfield,
    workflowpropertyfieldgroup,
    workflowusergroup
};

// Function which uses default to fallback
// Can be used for example like this 'getConfigValue("product", "cache.expire_duration")'
import * as defaults from './module/_defaults';
export const getConfigValue = (module, keys) => {
    // Merge default config with module config
    const mergedConfig = mergeDeep(defaults, (module in config) ? config[module] : {});

    // Now define our value by walking through our tree
    let value = mergedConfig;
    var namespaces = keys.split(".");
    for (var i = 0; i < namespaces.length; i++) {
        value = value[namespaces[i]];
    }
    return value;
};

export default config;