import {createRouter, createWebHistory} from "vue-router";

import layout from "layout/layout.vue";

// Dashboard related templates
import dashboardView from "view/dashboard/view";

const routes = [
    {
        path: "/",
        component: layout,
        children: [
            {
                path: "",
                name: "Dashboard view",
                component: dashboardView,
                props: {
                    module: "Dashboard",
                    action: "view"
                }
            }
        ]
    }
];

const router = createRouter({
    saveScrollPosition: false,
    history: createWebHistory(),
    routes
});
// router.beforeEach((to, from) => {
//     // Check for verification
//     if (to.path !== "/login" && !checkLogin()) {
//         router.push("/login");
//     }
// });
export default router;