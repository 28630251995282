<template>
    <headerElement></headerElement>
    <div class="App" :class="viewName">
        <router-view />
    </div>
    <footerElement></footerElement>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import headerElement from "layout/header";
import footerElement from "layout/footer";

export default defineComponent({
    name: "Layout",
    components: {
        headerElement,
        footerElement
    },
    setup() {
        // Our defaults
        const route = useRoute();

        // Define our parameters
        const viewName = route.name;

        return {
            viewName
        };
    },
});
</script>
