<template>
    <router-view />
</template>

<script>
import {defineComponent, provide, reactive} from "vue";

//
import SibApiV3Sdk from 'sib-api-v3-sdk';

// Our styles
import styles from 'css/app.scss';

// import { getUrlWithQueryItemReplaced } from "helpers/_structure.mjs";

export default defineComponent({
    name: "app",
    setup() {
        const generalData = reactive({
            finished: false,
            succesful: false,
            lists: {
                low: {
                    range: [-1, 20],
                    list_id: 12
                },
                medium: {
                    range: [20, 70],
                    list_id: 13
                },
                high: {
                    range: [70, 100],
                    list_id: 14
                }
            }
        });
        provide("generalData", generalData);


        let apiInstance = new SibApiV3Sdk.ContactsApi();
        let defaultClient = SibApiV3Sdk.ApiClient.instance;
        let apiKey = defaultClient.authentications['api-key'];
        apiKey.apiKey = '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';


        const resolveList = (percentage) => {
            let list = generalData.lists["medium"];
            Object.keys(generalData.lists).forEach(key => {
                if (percentage > generalData.lists[key].range[0] && percentage <= generalData.lists[key].range[1]) {
                    list = generalData.lists[key];
                }
            });
            return list;
        };
        const addExistingContact = (email, listId, percentage) => {
            let contactEmails = new SibApiV3Sdk.AddContactToList();
            let updateContact = new SibApiV3Sdk.UpdateContact();
            updateContact.attributes = {'score':percentage};

            contactEmails.emails = [email];
            apiInstance.addContactToList(listId, contactEmails).then(
                (data) => {
                    apiInstance.updateContact(email, updateContact).then(function() {
                        console.log('API called successfully.');
                        // console.log('API called successfully. Returned data: ' + JSON.stringify(data));
                        generalData.succesful = true;
                    }, function(error) {
                        console.error(error);
                    });
                }, (error) => {
                    console.error(error);
                }
            );
        }
        const addContact = (email, listId, percentage) => {
            let createContact = new SibApiV3Sdk.CreateContact();
            createContact.email = email;
            createContact.listIds = [listId];
            createContact.attributes = {
                score: percentage
            };

            apiInstance.createContact(createContact).then(
                (data) => {
                    // console.log('API called successfully. Returned data: ' + JSON.stringify(data));
                    generalData.succesful = true;
                },
                (error) => {
                    if (error.response.body.message === "Contact already exist") {
                        addExistingContact(email, listId, percentage);
                    }
                }
            );
        };
        const sendScore = (email, percentage) => {
            const list = resolveList(percentage);
            addContact(email, list.list_id, percentage);
        };
        provide("sendScore",sendScore);

    }
});
</script>

<style>

</style>