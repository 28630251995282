<template>
    <template v-if="!generalData.finished">
            <form action="/mail.php"  ref="formRef" method="post" @submit.prevent="handleSubmit" class="cs_form" :class="{intro : componentData.current_question < 0}">
                <fieldset class="intro" v-if="componentData.current_question < 0">
                    <div class="left">
                        <div class="Heading">
                            <h1 class="h1">Sustainability scan</h1>
                        </div>

                        <div class="Content">
                            <p>Het inzetten van technologie draagt bij aan een slim, veilig en duurzaam productiesysteem. Dat wat technisch nodig is om productiesystemen te verduurzamen, regelen wij.</p>
                            <p>Wilt u ook weten waar uw organisatie staat met betrekking tot een duurzaam productiesysteem? En in hoeverre duurzaamheid en efficiëntie in productie samengaan?</p>

                        </div>
                        <div class="bottom">
                            <div class="left">
                                <span class="title">Maak gratis gebruik van onze sustainability scan en ontdek in slechts 5 minuten waar kansen voor uw organisatie liggen.</span>
                            </div>
                            <div class="right">
                                <input class="form-control" type="text" v-model="emailAddress" placeholder="Uw e-mailadres" @input="validateEmailAddress($event.target.value)">
                                <button type="button" class="more bg2 hasafter" @click.prevent="handleNext" :disabled="!componentData.emailValidate">
                                    <span class="title">Start scan </span>
                                    <span v-html="Icons.arrow" class="icon"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <h3 class="h3">Waarom nu investeren <br>in sustainability?</h3>
                        <section class="Block Usp Overview">
                            <ul class="block-grid one-up">
                                <li>
                                    <article class="Block Usp Item">
                                        <span v-html="Icons.upwards" class="icon"></span>
                                        <h3 class="h3">De bevolkingsgroei en toename in welvaart leiden tot grote druk op hulpbronnen en milieu.</h3>
                                    </article>
                                </li>
                                <li>
                                    <article class="Block Usp Item">
                                        <span v-html="Icons.lightning" class="icon"></span>
                                        <h3 class="h3">Grondstof- en energieprijzen <br>stijgen sterk.</h3>
                                    </article>
                                </li>
                                <li>
                                    <article class="Block Usp Item">
                                        <span v-html="Icons.leaf" class="icon"></span>
                                        <h3 class="h3">Overheden, investeerders en consumenten vereisen dat bedrijven verduurzamen.</h3>
                                    </article>
                                </li>
                            </ul>
                        </section>
                    </div>
                </fieldset>
                <div class="Heading" v-if="componentData.current_question > -1">
                    <div class="progressBar">
                        <div class="inner"></div>
                    </div>
                </div>
                <template v-for="(question, key) in componentData.questions">
                    <fieldset :ref="el => (fieldsetRefs[key] = el)" :class="{ hidden : key !== componentData.current_question }">
                        <span class="current">Vraag {{componentData.current_question + 1}}</span>
                        <h3 class="h3">{{ question.title }}</h3>
                        <div class="element" :class="{CheckboxField: question.multiple === true}, {RadioField: question.multiple === false}" v-for="(answer, index) in question.answers">
                            <div :class="question.multiple === true ? `Checkbox_element` : `Radio_element` ">
                                <input :value="`${answer.levels}`" :type="question.multiple === true ? `checkbox` : `radio` " :name="key" :id="`${answer.title}${index}`" @click="checkChild(key, index)">
                                <label :for="`${answer.title}${index}`">
                                    <span class="title" v-html="answer.title"></span>
                                </label>
                            </div>
                        </div>
                        <template v-if="question.child" v-for="child in question.child">
                            <template v-if="child.visibility">
                                <h3 class="h3">{{ child.title }}</h3>
                                <div class="element" :class="{CheckboxField: child.multiple === true}, {RadioField: child.multiple === false}" v-for="(answer, index) in child.answers">
                                    <div :class="question.multiple === true ? `Checkbox_element` : `Radio_element` ">
                                        <input :value="`${answer.levels}`" :type="question.multiple === true ? `checkbox` : `radio` " :name="`${key}child`" :id="`child${answer.title}${index}`">
                                        <label :for="`child${answer.title}${index}`">
                                            <span class="title" v-html="answer.title"></span>
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <span class="note" style="display: none;">U dient een antwoord in te vullen</span>
                    </fieldset>
                </template>
                <div class="bottom" v-if="!isFirst()">
                    <nav>
                        <button type="button" class="more bg2 hasbefore" @click.prevent="handlePrev" v-if="componentData.current_question > -1">
                            <span v-html="Icons.arrow" class="icon"></span>
                            <span class="title">Vorige</span>
                        </button>
                        <button type="button" class="more bg2 hasafter" @click.prevent="handleNext" v-if="!isLast()">
                            <span class="title">Volgende</span>
                            <span v-html="Icons.arrow" class="icon"></span>
                        </button>
                        <button type="submit" class="more bg2 hasafter" v-if="isLast()">
                            <span class="title">Toon resultaten</span>
                            <span v-html="Icons.arrow" class="icon"></span>
                        </button>
                    </nav>
                </div>
            </form>
    </template>
    <div class="result" v-else>
        <div class="left">
            <div class="top">
                <span class="result" :class="{bad : resultData.total <= 20, average : resultData.total < 70 && resultData.total > 20, good : resultData.total >= 70}">
                    {{ resultData.percentage }}
                </span>
            </div>
            <div class="bottom">
                <div class="legenda">
                    <div class="inner">
                        <div class="bad">0 - 20</div>
                        <div class="average">20 - 70</div>
                        <div class="good">70 - 100</div>
                    </div>
                </div>
                <h3 class="h3">
                    U scoort
                    <span class="good" v-if="resultData.percentage >= 70">goed</span>
                    <span class="average" v-else-if="resultData.percentage && resultData.percentage > 20">matig</span>
                    <span class="bad" v-else-if="resultData.percentage <= 20">slecht</span>
                    op duurzaamheid
                </h3>
                <div class="Content" v-if="resultData.total">
                    <span v-if="resultData.percentage >= 95">
                        Onze Maturity scan geeft u een score van <span>{{ resultData.percentage }} uit 100</span>. U bent al erg goed bezig op het gebied van duurzaamheid, toch denken wij dat er nog kansen liggen om de duurzaamheid van uw bedrijf te verbeteren. Neem contact op met FMI ImProvia voor een vrijblijvende afspraak om te bespreken hoe u dit in uw organisatie richting en inhoud kunt geven.
                    </span>
                    <span v-else-if="resultData.percentage >= 70">
                        Onze Maturity scan geeft u een score van <span>{{ resultData.percentage }} uit 100</span>. Dit laat zien dat er nog kansen liggen om de duurzaamheid van uw bedrijf te verbeteren. Neem contact op met FMI ImProvia voor een vrijblijvende afspraak om te bespreken hoe u dit in uw organisatie richting en inhoud kunt geven.
                    </span>
                    <span v-else-if="resultData.percentage > 20">
                        Onze Maturity scan geeft u een score van <span>{{ resultData.percentage }} uit 100</span>. Dit laat zien dat er nog kansen liggen om de duurzaamheid van uw bedrijf te verbeteren. Neem contact op met FMI ImProvia voor een vrijblijvende afspraak om te bespreken hoe u dit in uw organisatie richting en inhoud kunt geven.
                    </span>
                    <span v-else-if="resultData.percentage <= 20">
                        Onze Maturity scan geeft u een score van <span>{{ resultData.percentage }} uit 100</span>. Dit laat zien dat er nog kansen liggen om de duurzaamheid van uw bedrijf te verbeteren. Neem contact op met FMI ImProvia voor een vrijblijvende afspraak om te bespreken hoe u dit in uw organisatie richting en inhoud kunt geven.
                    </span>
                </div>
                <nav>
                    <a href="matilto:robin.keizer@fmi-improvia.com" target="_blank" class="more bg2 hasafter">
                        <span class="title">Ja, ik wil gratis advies!</span>
                        <span class="icon">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                <rect y="23.5" style="fill:#FFFFFF;" width="48.2" height="2.4"/>
                                <polygon style="fill:#FFFFFF;" points="24.9,47.3 23.3,45.5 46.4,24.7 23.3,3.9 24.9,2.2 50,24.7 "/>
                            </svg>
                        </span>
                    </a>
                </nav>
            </div>
        </div>
        <div class="right">
            <div class="Heading">
                <h3 class="h3">Waarmee kan FMI ImProvia u helpen?</h3>
                <ul class="block-grid">
                    <li>
                        <span v-html="Icons.correct" class="icon"></span>
                        <span class="title">Digitalisering & Automatisering</span>
                    </li>
                    <li>
                        <span v-html="Icons.correct" class="icon"></span>
                        <span class="title">Industriële veiligheid en Cybersecurity</span>
                    </li>
                    <li>
                        <span v-html="Icons.correct" class="icon"></span>
                        <span class="title">Regievoering over innovation to market of New Product Introduction Processes.</span>
                    </li>
                </ul>
            </div>
            <div class="inner">
                <nav>
                    <a href="https://www.fmi-improvia.com/dienst/smart-industry-solutions" target="_blank" class="more outline hasafter">
                        <span class="title">Meer informatie</span>
                        <span v-html="Icons.arrowtheme" class="icon"></span>
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<style>
.hidden {
    display:none;
}
</style>

<script>
import {defineComponent, reactive, ref, provide, onMounted, inject} from "vue";
import {Icons} from "icons";
export default defineComponent({
    name: "dashboardView",
    components: {},
    setup() {
        const formRef = ref();
        // Stel je antwoord vraag 1 antwoord 2, vraag 2 antwoord 3
        // Dan tellen we bij total dus 2 + 3 = 5
        // Bij resultData.levels[2].count += 1 en bij resultData.levels[3].count ook += 1

        const resultData = reactive({
            total: 0,
            score: null,
            percentage: null,
            levels: [
                {
                    "title": "Telt niet mee",
                    "count": 0
                },
                {
                    "title": "Niveau 1",
                    "count": 0
                },
                {
                    "title": "Niveau 2",
                    "count": 0
                },
                {
                    "title": "Niveau 3",
                    "count": 0
                },
                {
                    "title": "Niveau 4",
                    "count": 0
                },
                {
                    "title": "Niveau 5",
                    "count": 0
                }
            ]
        });

        const generalData = inject("generalData");

        const emailAddress = ref();
        const fieldsetRefs = reactive([]);
        const componentData = reactive({
            total: 0,
            current_question: -1,
            emailValidate: false,
            questions: [
                {
                    "title": "In hoeverre leeft sustainability binnen uw organisatie? Meerdere opties mogelijk.",
                    "multiple": true,
                    "answers": [
                        {
                            "title"     : "We beseffen dat we er iets mee moeten.",
                            "levels"    : [ 1 ]
                        },
                        {
                            
                            "title"     : "We zijn vooral bezig om kennis over het onderwerp te vergaren.",
                            "levels"    : [ 2 ]
                        },
                        {
                            
                            "title"     : "Er worden al initiatieven ondernomen gericht op verbetering en efficiëntie.",
                            "levels"    : [ 3 ]
                        },
                        {
                            
                            "title"     : "We kijken voornamelijk naar houding en gedrag.",
                            "levels"    : [ 4 ]
                        },
                        {
                            
                            "title"     : "Sustainability is volledig ingebed in onze cultuur.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "De initiatieven die lopen op het gebied van sustainability zijn voornamelijk gericht op:",
                    "multiple": false,
                    "answers": [
                        {

                            "title"     : "Graag ‘iets’ willen doen.",
                            "levels"    : [ 1 ]
                        },
                        {

                            "title"     : "Het in acht nemen van de wet- en regelgeving.",
                            "levels"    : [ 2 ]
                        },
                        {

                            "title"     : "Een procesmatige aanpak.",
                            "levels"    : [ 3 ]
                        },
                        {

                            "title"     : "Het behalen van bedrijfsdoelstellingen.",
                            "levels"    : [ 4 ]
                        },
                        {

                            "title"     : "Medewerkers stimuleren om verantwoordelijkheid te nemen.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "Hoe bewust is uw organisatie als het gaat om sustainability?",
                    "multiple": false,
                    "answers": [
                        {

                            "title"     : "<strong>Niet bewust</strong><br>We weten dat er verandering nodig is.",
                            "levels"    : [ 1 ]
                        },
                        {

                            "title"     : "<strong>Een beetje bewust</strong><br>We zijn ons ervan bewust dat het bedrijf impact kan maken.",
                            "levels"    : [ 2 ]
                        },
                        {

                            "title"     : "<strong>Middelmatig bewust</strong><br>Thema’s zoals energiegebruik of afval bespreken we dagelijks in de productievergadering.",
                            "levels"    : [ 3 ]
                        },
                        {

                            "title"     : "<strong>Normaal bewust</strong><br>Duurzaamheid is onderdeel van elk afdelingsoverleg.",
                            "levels"    : [ 4 ]
                        },
                        {

                            "title"     : "<strong>Hoog bewust</strong><br>Het is onderdeel van het bedrijfs-DNA.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "Is er binnen uw organisatie iemand verantwoordelijk voor sustainability?",
                    "multiple": false,
                    "answers": [
                        {

                            "title"     : "Hiervoor is geen verantwoordelijke aangewezen.",
                            "levels"    : [ 2 ]
                        },
                        {

                            "title"     : "Er is een persoon verantwoordelijk voor sustainability, maar heeft dit als neventaak naast andere werkzaamheden.",
                            "levels"    : [ 3 ]
                        },
                        {

                            "title"     : "We hebben een fulltime dedicated sustainability expert in huis.",
                            "levels"    : [ 4 ]
                        },
                        {

                            "title"     : "We hebben een dedicated team van experts op het gebied van sustainability in het bedrijf.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "In hoeverre is het management betrokken bij sustainability binnen uw organisatie?",
                    "multiple": false,
                    "answers": [
                        {

                            "title"     : "Het management is niet betrokken.",
                            "levels"    : [ 2 ]
                        },
                        {

                            "title"     : "Het management is verantwoordelijk voor de uitvoering, maar participeert niet direct.",
                            "levels"    : [ 3 ]
                        },
                        {

                            "title"     : "Het management is primair verantwoordelijk voor de uitvoering en participeert direct.",
                            "levels"    : [ 4 ]
                        },
                        {

                            "title"     : "KPI’s met betrekking tot duurzaamheid zijn integraal onderdeel van de bedrijfsdoelstellingen en het jaarverslag.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "Hoe komen verbetertrajecten omtrent sustainability tot stand binnen uw organisatie?",
                    "multiple": false,
                    "answers": [
                        {

                            "title"     : "Er zijn geen verbetertrajecten.",
                            "levels"    : [ 1 ]
                        },
                        {

                            "title"     : "Ideeën met verbeteringen worden door individuele werknemers bepaald naar eigen inzicht.",
                            "levels"    : [ 2 ]
                        },
                        {

                            "title"     : "Onderwerpen worden door multidisciplinaire teams bepaald naar eigen inzicht.",
                            "levels"    : [ 3 ]
                        },
                        {

                            "title"     : "Onderwerpen worden bepaald naar aanleiding van een analyse van metingen en marktrapporten.",
                            "levels"    : [ 4 ]
                        },
                        {

                            "title"     : "Medewerkers zijn betrokken bij analyses en verbeteringen.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "Wordt er bij project management rekening gehouden met sustainability?",
                    "multiple": false,
                    "answers": [
                        {

                            "title"     : "Er is alleen aandacht voor de business case en planning. ",
                            "levels"    : [ 1 ]
                        },
                        {

                            "title"     : "Duurzaamheid is onderschikt aan de business case en planning.",
                            "levels"    : [ 2 ]
                        },
                        {

                            "title"     : "De duurzaamheidsdoelen zijn opgesteld in een project charter.",
                            "levels"    : [ 3 ]
                        },
                        {

                            "title"     : "We houden rekening met vertraging en extra investeringen omwille van duurzaamheid.",
                            "levels"    : [ 4 ]
                        },
                        {

                            "title"     : "Duurzaamheid, de business case & planning zijn volledig geïntegreerd.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "Zijn er budgetten aanwezig voor sustainability in uw organisatie?",
                    "multiple": false,
                    "answers": [
                        {
                            "title"     : "Er is geen budget voor.",
                            "levels"    : [ 2 ],
                            "opensChild"    : false,
                        },
                        {
                            "title"     : "Er is goedkeuring voor budget op projectbasis. Medewerkers mogen er niet teveel tijd aan besteden.",
                            "levels"    : [ 3 ],
                            "opensChild"    : true,
                        },
                        {
                            "title"     : "Er is een basis budget beschikbaar. Medewerkers mogen er tijd aan besteden.",
                            "levels"    : [ 4 ],
                            "opensChild"    : true,
                        },
                        {
                            "title"     : "Er is voldoende budget aanwezig en medewerkers krijgen er de ruimte en tijd voor om aan te besteden.",
                            "levels"    : [ 5 ],
                            "opensChild"    : true,
                        }
                    ],
                    "child": [
                        {
                            "title": "Waar is het budget overwegend voor gealloceerd?",
                            "multiple": false,
                            "visibility": false,
                            "answers": [
                                {
                                    "title"     : "Energieopwekking verduurzamen.",
                                    "levels"    : [ 0 ]
                                },
                                {
                                    "title"     : "Energieverbruik verminderen.",
                                    "levels"    : [ 0 ]
                                },
                                {
                                    "title"     : "Energieopwekking verduurzamen én energieverbruik verminderen.",
                                    "levels"    : [ 0 ]
                                },
                                {
                                    "title"     : "Geen idee.",
                                    "levels"    : [ 0 ]
                                }
                            ]
                        },
                    ]
                },
                {
                    "title": "In hoeverre worden de energiestromen binnen uw organisatie gemeten?",
                    "multiple": false,
                    "answers": [
                        {
                            "title"     : "Er zijn alleen gegevens bekend van de totale fabriek.",
                            "levels"    : [ 1 ]
                        },
                        {
                            "title"     : "Er wordt een eerste segmentering gemaakt in verbruiksgegevens (bijvoorbeeld op basis van productielijn of hal).",
                            "levels"    : [ 2 ]
                        },
                        {
                            "title"     : "Alle grote energie- en afvalstromen worden gemeten.",
                            "levels"    : [ 3 ]
                        },
                        {
                            "title"     : "Verbruiksgegevens zijn beschikbaar op een dashboard.",
                            "levels"    : [ 4 ]
                        },
                        {
                            "title"     : "Verbruiksgegevens zijn real time voor elke werknemer inzichtelijk.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "In hoeverre is er gekeken naar de circulariteit van uw product?",
                    "multiple": false,
                    "answers": [
                        {
                            "title"     : "Er is geen informatie over de impact van het product gedurende de levensduur.",
                            "levels"    : [ 1 ]
                        },
                        {
                            "title"     : "Er zijn enkele kerngegevens bekend van de eigen productie, niet van de ketenefficiëntie.",
                            "levels"    : [ 2 ]
                        },
                        {
                            "title"     : "Er is een Life Cycle Analysis uitgevoerd.",
                            "levels"    : [ 3 ]
                        },
                        {
                            "title"     : "Er zijn jaarlijks kleine verbeteringen in de LCA van het product.",
                            "levels"    : [ 4 ]
                        },
                        {
                            "title"     : "LCA is geoptimaliseerd waarbij ook is gekeken naar Lease/Servitization.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "Bent u op de hoogte van de trends en ontwikkelingen binnen Industry 4.0 en hoe deze kunnen helpen bij duurzaamheidsdoelstellingen?",
                    "multiple": false,
                    "answers": [
                        {
                            "title"     : "Niet op de hoogte.",
                            "levels"    : [ 2 ]
                        },
                        {
                            "title"     : "Een beetje op de hoogte.",
                            "levels"    : [ 3 ]
                        },
                        {
                            "title"     : "Op de hoogte.",
                            "levels"    : [ 4 ]
                        }
                    ]
                },
                {
                    "title": "In hoeverre bent u het eens met de volgende uitspraak: Het toepassen van Smart Industry technologieën binnen productiesystemen is noodzakelijk om de duurzaamheidstransitie door te kunnen maken.",
                    "multiple": false,
                    "answers": [
                        {
                            "title"     : "Sterk mee oneens.",
                            "levels"    : [ 1 ]
                        },
                        {
                            "title"     : "Oneens.",
                            "levels"    : [ 2 ]
                        },
                        {
                            "title"     : "Neutraal.",
                            "levels"    : [ 3 ]
                        },
                        {
                            "title"     : "Eens.",
                            "levels"    : [ 4 ]
                        },
                        {
                            "title"     : "Sterk mee eens.",
                            "levels"    : [ 5 ]
                        }
                    ]
                },
                {
                    "title": "In hoeverre maakt u gebruik van data ten gunste van een duurzame en efficiënte productie?",
                    "multiple": false,
                    "answers": [
                        {
                            "title"     : "De data is voornamelijk beschrijvend/registrerend. ",
                            "levels"    : [ 1 ]
                        },
                        {
                            "title"     : "Data gebruiken we om te analyseren en te verklaren.",
                            "levels"    : [ 2 ]
                        },
                        {
                            "title"     : "We sturen onze productie indirect bij aan de hand van voorspellingen en trends.",
                            "levels"    : [ 3 ]
                        },
                        {
                            "title"     : "We sturen onze productie direct bij aan de hand van data en werken met scenario’s.",
                            "levels"    : [ 4 ]
                        }
                    ]
                },
                {
                    "title": "Selecteer op welke items volgens u het gebruik van cobots een positief effect op duurzaamheid heeft.",
                    "multiple": true,
                    "answers": [
                        {
                            "title"     : "Afval vermindering.",
                            "levels"    : [ 0 ]
                        },
                        {
                            "title"     : "Vermindering energie verbruik.",
                            "levels"    : [ 0 ]
                        },
                        {
                            "title"     : "Reshoring.",
                            "levels"    : [ 0 ]
                        },
                        {

                            "title"     : "Ruimtebesparende productie.",
                            "levels"    : [ 0 ]
                        },
                        {
                            "title"     : "Medewerkers tevredenheid en inzetbaarheid.",
                            "levels"    : [ 0 ]
                        },
                        {
                            "title"     : "Geen.",
                            "levels"    : [ 0 ]
                        }
                    ]
                }
            ]
        });

        const isFirst = () => {
            return componentData.current_question < 0;
        };
        const isLast = () => {
            return componentData.current_question === (componentData.questions.length -1);
        };

        const handleProgressBar = () => {
            const progressBar = document.querySelector(".progressBar .inner");
            if (progressBar){
                progressBar.style.width = 100 / componentData.questions.length * (componentData.current_question + 1) +'%';
            }
        };

        const handlePrev = () => {
            componentData.current_question -= 1;
            setTimeout(() => {
                handleProgressBar();
                handleSvg();
            }, 50)
        };

        const handleNext = () => {
            if (emailAddress.value){
                const notes = document.querySelectorAll('.note');
                if (componentData.current_question > -1) {
                    const checkedAnswers = Array.from(fieldsetRefs[componentData.current_question].querySelectorAll("input:checked"));
                    if (!checkedAnswers.length){
                        notes.forEach((item) => {
                            item.style.display = 'block';
                        });
                        return;
                    }
                }
                if (!isLast()){
                    componentData.current_question += 1;
                    notes.forEach((item) => {
                        item.style.display = 'none';
                    });
                    setTimeout(() => {
                        handleSvg();
                        handleProgressBar();
                    }, 50)
                } else {
                    generalData.finished = true;
                }
            }
        };

        const checkChild = (key, answer) => {
            if (componentData.questions[key].child){
                if (componentData.questions[key].answers[answer].opensChild){
                    componentData.questions[key].child[0].visibility = true;
                } else {
                    componentData.questions[key].child[0].visibility = false;
                }
            }
        };

        const buildAjaxdata = (formData, total) => {
            let data = {
                emailadress : emailAddress.value,
                results: {},
                total: total
            };
            for (let [key, prop] of formData) {
                if (!(key in data.results)) {
                    data.results[key] = {
                        question : componentData.questions[key].title,
                        answers: []
                    }
                }
                data.results[key]["answers"].push(componentData.questions[key]["answers"][prop - 1]["title"])
            }
            return data;
        };

        // const ajaxSubmit = (formData, total) => {
        //     var request = new XMLHttpRequest();
        //     var url = "/mail.php";
        //     request.open("POST", url, true);
        //     request.setRequestHeader("Content-Type", "application/json");
        //     request.onreadystatechange = function () {
        //         if (request.readyState === 4 && request.status === 200) {
        //             var jsonData = JSON.parse(request.response);
        //             console.log(jsonData.success);
        //             console.log("@TODO handle success state");
        //         }
        //     };
        //     let data = buildAjaxdata(formData, total);
        //     request.send(JSON.stringify(data));
        // }
        const sendScore = inject("sendScore");
        const handleResult = () => {
            const calculation = (parseInt(resultData.total) / parseInt(componentData.total)) * 100;
            const total = Number(calculation.toFixed(1));
            resultData.score = Math.max(0, Math.min(Number(total / 10).toFixed(1), 10));
            resultData.percentage = Math.max(0, Math.min(Number(resultData.score * 10), 100));

            sendScore(emailAddress.value, resultData.percentage);
        };
        const handleSubmit = () => {
            if (!isLast()) return;
            // Only when all steps been passed
            handleNext();
            if (generalData.finished){
                const formData = new FormData(formRef.value);
                for(const pair of formData.entries()) {
                    resultData.total += parseInt(pair[1]);
                }

                setTimeout(handleSvg, 5);
                handleResult();
                // ajaxSubmit(formData, total);
            }
        };

        const handleSvg = () => {
            const icons = document.querySelectorAll(".icon");
            if (icons){
                icons.forEach((icon)=>{
                    if(icon.dataset.filename){
                        const filename = icon.dataset.filename;
                        icon.innerHTML = `<img src='/icons/${filename}.svg'></img>`;
                    }
                });
            }
        };

        const validateEmailAddress = (e) => {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)){
                componentData.emailValidate = true;
            } else{
                componentData.emailValidate = false;
            }
        };

        onMounted(() => {
            componentData.questions.forEach((question) => {
                const highestLevel = parseInt(question.answers[question.answers.length -1].levels);
                componentData.total += highestLevel;
            });
            handleSvg();
        });

        return {
            emailAddress,
            formRef,
            resultData,
            componentData,
            generalData,
            fieldsetRefs,
            Icons,
            checkChild,
            validateEmailAddress,
            handleSubmit,

            isFirst,
            isLast,

            handlePrev,
            handleNext
        }
    }
});
</script>
