<template>
    <header id="Header">
        <div class="container">
            <div class="left">
                <a href="https://www.fmi-improvia.com/">
                    <img src="images/logo.png" alt="FMI ImProvia - Quickscan">
                </a>
            </div>
            <div class="right">
                <nav>
                    <a href="mailto:robin.keizer@fmi-improvia.com" class="more-inline hasafter">
                        <span class="title">Vragen?</span>
                        <span v-html="Icons.mobile" class="icon"></span>
                    </a>
                    <a href="/" class="more-inline hasafter" v-if="generalData.finished">
                        <span class="title">Terug naar home</span>
                        <span v-html="Icons.arrowtheme" class="icon"></span>
                    </a>
                    <a href="https://www.fmi-improvia.com/dienst/smart-industry-solutions" class="more-inline hasafter" v-if="!generalData.finished">
                        <span class="title">Sluit maturity scan</span>
                        <span v-html="Icons.close" class="icon"></span>
                    </a>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
import {defineComponent } from "vue";
import {Icons} from "icons";
import { inject } from "vue";

// Components
// ...

export default defineComponent({
    name: "headerElement",
    components: {
    },
    setup() {
        const generalData = inject("generalData");
        return {
            generalData,
            Icons
        };
    },
});
</script>
